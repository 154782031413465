/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital@1&display=swap");
$color-primary: #031832;
$color-secondary: #04234a;
$mobile-res: 390px;
$tablet-res: 640px;
$font-primary: "Chronicle Text G1 Roman", ChronicleTextG1-Roman, serif;

body {
  font-family: $font-primary;
  margin: 0;
  position: relative;
  height: 100%;
}

html {
  height: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.text-lg {
  font-size: 0.8rem;
  font-weight: 100;
}

.btn-primary:hover {
  background-color: $color-secondary;
  transition: 0.5ms ease-in-out;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.pt-2 {
  padding-top: 0.5rem;
}

.pt-4 {
  padding-top: 1rem;
}

// .px-4 {
//   padding-left: 1rem;
//   padding-right: 1rem;
// }

.list-no-decoration {
  list-style: none;
}

.flex {
  display: flex;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center{
  align-items: center;
}

.justify-content {
  justify-content: center;
}

.text-primary {
  color: $color-primary;
}

.background-primary {
  background: $color-primary;
}

.position-relative {
  position: relative;
}

.proportion {
  height: 5rem;
  overflow: auto;
  flex-grow: 1;
  padding-left: 12px;

  .btn-headding {
    font-size: 11px;
  }

  .btn {
    text-transform: unset;
  }
}

.left-5 {
  left: 2.7rem;
}

.border-top-5 {
  border-radius: 0.5rem 0.5rem 0rem 0rem;
}

.text-center {
  text-align: center;
}

.mr-12 {
  margin-right: 3rem;
}

.mx-12 {
  margin: 0 3rem;
}

#cdk-overlay-0 {
  overflow: hidden !important;
}

.cdk-overlay-pane {
  overflow: hidden !important;
}

.justify-between {
  justify-content: space-between;
}

.pb-4 {
  padding-bottom: 1rem;
}

.logo-img {
  width: 80%;
  height: 50%;
}
.mb-4 {
  margin-bottom: 1rem;
}

@media screen and (max-width: $tablet-res) and (min-width: $mobile-res) {
  .sm-w-100 {
    width: 100%;
  }
}
